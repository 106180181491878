/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
*/

@import '@angular/material/theming';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Fonts
@import 'https://fonts.googleapis.com/css?family=Material+Icons';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');
$fontConfig: (
    display-4: mat-typography-level(112px, 112px, 400, 'Noto Sans', -0.0134em),
    display-3: mat-typography-level(56px, 56px, 400, 'Noto Sans', -0.0089em),
    display-2: mat-typography-level(45px, 48px, 400, 'Noto Sans', 0.0000em),
    display-1: mat-typography-level(34px, 40px, 400, 'Noto Sans', 0.0074em),
    headline: mat-typography-level(24px, 32px, 400, 'Noto Sans', 0.0000em),
    title: mat-typography-level(20px, 32px, 400, 'Noto Sans', 0.0075em),
    subheading-2: mat-typography-level(16px, 28px, 400, 'Noto Sans', 0.0094em),
    subheading-1: mat-typography-level(15px, 24px, 400, 'Noto Sans', 0.0067em),
    body-2: mat-typography-level(14px, 24px, 400, 'Noto Sans', 0.0179em),
    body-1: mat-typography-level(14px, 20px, 400, 'Noto Sans', 0.0179em),
    button: mat-typography-level(14px, 14px, 400, 'Noto Sans', 0.0893em),
    caption: mat-typography-level(12px, 20px, 400, 'Noto Sans', 0.0333em),
    input: mat-typography-level(inherit, 1.125, 400, 'Noto Sans', 1.5px)
);
// Foreground Elements
// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text,
        0.87);
$dark-accent-text: rgba($dark-primary-text,
        0.54);
$dark-disabled-text: rgba($dark-primary-text,
        0.38);
$dark-dividers: rgba($dark-primary-text,
        0.12);
$dark-focused: rgba($dark-primary-text,
        0.12);
$mat-light-theme-foreground: (
    base: black,
    divider: $dark-dividers,
    dividers: $dark-dividers,
    disabled: $dark-disabled-text,
    disabled-button: rgba($dark-text, 0.26),
    disabled-text: $dark-disabled-text,
    elevation: black,
    secondary-text: $dark-accent-text,
    hint-text: $dark-disabled-text,
    accent-text: $dark-accent-text,
    icon: $dark-accent-text,
    icons: $dark-accent-text,
    text: $dark-primary-text,
    slider-min: $dark-primary-text,
    slider-off: rgba($dark-text, 0.26),
    slider-off-active: $dark-disabled-text,
);
// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text,
        0.7);
$light-disabled-text: rgba($light-primary-text,
        0.5);
$light-dividers: rgba($light-primary-text,
        0.12);
$light-focused: rgba($light-primary-text,
        0.12);
$mat-dark-theme-foreground: (
    base: $light-text,
    divider: $light-dividers,
    dividers: $light-dividers,
    disabled: $light-disabled-text,
    disabled-button: rgba($light-text, 0.3),
    disabled-text: $light-disabled-text,
    elevation: black,
    hint-text: $light-disabled-text,
    secondary-text: $light-accent-text,
    accent-text: $light-accent-text,
    icon: $light-text,
    icons: $light-text,
    text: $light-text,
    slider-min: $light-text,
    slider-off: rgba($light-text, 0.3),
    slider-off-active: rgba($light-text, 0.3),
);
// Background config
// Light bg
$light-background: #fafafa;
$light-bg-darker-5: darken($light-background,
        5%);
$light-bg-darker-10: darken($light-background,
        10%);
$light-bg-darker-20: darken($light-background,
        20%);
$light-bg-darker-30: darken($light-background,
        30%);
$light-bg-lighter-5: lighten($light-background,
        5%);
$dark-bg-tooltip: lighten(#2c2c2c,
        20%);
$dark-bg-alpha-4: rgba(#2c2c2c,
        0.04);
$dark-bg-alpha-12: rgba(#2c2c2c,
        0.12);
$mat-light-theme-background: (
    background: $light-background,
    status-bar: $light-bg-darker-20,
    app-bar: $light-bg-darker-5,
    hover: $dark-bg-alpha-4,
    card: $light-bg-lighter-5,
    dialog: $light-bg-lighter-5,
    tooltip: $dark-bg-tooltip,
    disabled-button: $dark-bg-alpha-12,
    raised-button: $light-bg-lighter-5,
    focused-button: $dark-focused,
    selected-button: $light-bg-darker-20,
    selected-disabled-button: $light-bg-darker-30,
    disabled-button-toggle: $light-bg-darker-10,
    unselected-chip: $light-bg-darker-10,
    disabled-list-option: $light-bg-darker-10,
);
// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background,
        5%);
$dark-bg-lighter-10: lighten($dark-background,
        10%);
$dark-bg-lighter-20: lighten($dark-background,
        20%);
$dark-bg-lighter-30: lighten($dark-background,
        30%);
$light-bg-alpha-4: rgba(#fafafa,
        0.04);
$light-bg-alpha-12: rgba(#fafafa,
        0.12);
// Background palette for dark themes.
$mat-dark-theme-background: (
    background: $dark-background,
    status-bar: $dark-bg-lighter-20,
    app-bar: $dark-bg-lighter-5,
    hover: $light-bg-alpha-4,
    card: $dark-bg-lighter-5,
    dialog: $dark-bg-lighter-5,
    tooltip: $dark-bg-lighter-20,
    disabled-button: $light-bg-alpha-12,
    raised-button: $dark-bg-lighter-5,
    focused-button: $light-focused,
    selected-button: $dark-bg-lighter-20,
    selected-disabled-button: $dark-bg-lighter-30,
    disabled-button-toggle: $dark-bg-lighter-10,
    unselected-chip: $dark-bg-lighter-20,
    disabled-list-option: $dark-bg-lighter-10,
);
// Compute font config
@include mat-core($fontConfig);

// Theme Config
body {
    --primary-color: #84c335;
    --primary-lighter-color: #daedc2;
    --primary-darker-color: #67ae22;
    --text-primary-color: #{$dark-primary-text};
    --text-primary-lighter-color: #{$dark-primary-text};
    --text-primary-darker-color: #{$dark-primary-text};
}

$mat-primary: (
    main: #84c335,
    lighter: #daedc2,
    darker: #67ae22,
    200: #84c335,
    // For slide toggle,
    contrast: (main: $light-primary-text, lighter: $light-primary-text, darker: $light-primary-text, )
);
$theme-primary: mat-palette($mat-primary,
        main,
        lighter,
        darker);

body {
    --accent-color: #e6e5e5;
    --accent-lighter-color: #f8f7f7;
    --accent-darker-color: #dbdada;
    --text-accent-color: #{$light-primary-text};
    --text-accent-lighter-color: #{$light-primary-text};
    --text-accent-darker-color: #{$light-primary-text};
}

$mat-accent: (
    main: #e6e5e5,
    lighter: #f8f7f7,
    darker: #dbdada,
    200: #e6e5e5,
    // For slide toggle,
    contrast: (main: $dark-primary-text, lighter: $dark-primary-text, darker: $dark-primary-text, )
);
$theme-accent: mat-palette($mat-accent,
        main,
        lighter,
        darker);

body {
    --warn-color: #ff0000;
    --warn-lighter-color: #ffb3b3;
    --warn-darker-color: #ff0000;
    --text-warn-color: #{$light-primary-text};
    --text-warn-lighter-color: #{$dark-primary-text};
    --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (
    main: #ff0000,
    lighter: #ffb3b3,
    darker: #ff0000,
    200: #ff0000,
    // For slide toggle,
    contrast: (main: $light-primary-text, lighter: $dark-primary-text, darker: $light-primary-text, )
);
$theme-warn: mat-palette($mat-warn,
        main,
        lighter,
        darker);
;
$theme: mat-light-theme($theme-primary,
        $theme-accent,
        $theme-warn);
$altTheme: mat-dark-theme($theme-primary,
        $theme-accent,
        $theme-warn);
// Theme Init
@include angular-material-theme($theme);

.theme-alternate {
    @include angular-material-theme($altTheme);
}

.wingsmed-row {
    margin: 0;
    flex-flow: column nowrap;
}

.col-md-12 {
    padding: 0;
}

.container {
    display: flex;
    flex-direction: column;
}

.container .mat-drawer-container {
    flex: 1 1 auto;
    position: unset;
}

// Specific component overrides, pieces that are not in line with the general theming
// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
    padding: 0 0.5em;
    margin: 0 .65em;
    min-width: 3em;
    line-height: 36.4px
}

.mat-standard-chip {
    padding: .5em .85em;
    min-height: 2.5em;
}

.material-icons {
    font-size: 24px;
    font-family: 'Material Icons', 'Material Icons';

    .mat-badge-content {
        font-family: 'Noto Sans';
    }
}

.mat-icon {
    vertical-align: middle;
}

.mat-row,
.mat-header-row,
.mat-footer-row {

    th.mat-header-cell,
    td.mat-cell,
    td.mat-footer-cell {
        border-bottom-width: 1px;
        border-bottom-style: solid;
        padding: 10px 0px 10px 20px;
    }
}

td.mat-cell {
    .mat-raised-button {
        padding: 0 8px;
    }
}

.mat-card .mat-card-header-text {
    margin: 0 16px 0 0;
}

mat-tab-body .mat-tab-body-content,
.div-body-content {
    padding: 20px;
}

.cdk-overlay-pane .errorSnackBar {
    background: #ffe4df;
    color: #ea1b24;
    margin-top: 55px;
    text-align: center;
}

.cdk-overlay-pane .successSnackBar {
    background: #f0ffdc;
    color: #008000;
    margin-top: 55px;
    text-align: center;
}

.loading-shade {
    display: inline-block;
    vertical-align: middle;
}

.page-title {
    vertical-align: middle;
    margin-right: 5px;
}

.checkbox label,
.radio label,
label {
    font-size: 1em;
}

.wingsapp-card-content {
    padding-top: 10px;
}

.wingsmed-mattab-container {
    margin: 10px;
}

.wingsmed-tab-actions {
    padding-top: 20px;
}

.wingsmed-tab-actions {
    button {
        margin-right: 10px;
    }
}

.wingsmed-patient-signature {
    width: 300px;
}

.wingsmed-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mat-radio-button-2 {
    margin-left: 25px;
}

.mat-radio-button-newline label {
    display: flex; align-items: flex-start;
}

.wingsmed-dynamic-question {
    display: flex;
}

.wingsmed-error-message {
    margin-left: 25px;
    color: #ff0000;
}

.wingsmed-examination-h2,
.wingsmed-examination-h3 {
    width: 100%;
}

.form-row .wingsmed-examination-h2 {
    margin-bottom: 6px;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
    border-radius: 4px 4px 0 0;
    padding: 0 0 0 .75em;
    background: none;
}

.wingsmed-expansion-headers-align .mat-expansion-panel-header-title,
.wingsmed-expansion-headers-align .mat-expansion-panel-header-description {
    flex-basis: 0;
}

.wingsmed-expansion-headers-align .mat-expansion-panel-header-description {
    justify-content: space-between;
    align-items: center;
}

.wingsmed-expansion-headers-align .mat-form-field+.mat-form-field {
    margin-left: 8px;
}

.mat-accordion .mat-expansion-panel {
    box-shadow: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.wingsmed-menu-button {
    width: 100%;
}

.wingsmed-menu-button .mat-button-wrapper mat-icon {
    float: left;
    margin-top: 5px;
}

.wingsmed-menu-button .mat-button-wrapper span {
    margin-left: 5px;
}

.mat-column-actions .wingsmed-action-button {
    margin-left: 10px;
}

.wingsmed-patientsummary-box {
    margin-bottom: 15px;
    padding: 20px;
    margin-top: 5px;
}

.wingsmed-forbidden-error {
    background: #ffe4df;
    color: #ea1b24;
    margin: 10px;
    padding: 10px;
    text-align: center;
}